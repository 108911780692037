<template>
    <div class="row">
        <div class="col-12">
            <div class="card card m-b-0">
                <!-- .chat-row -->
                <div class="chat-main-box">
                    <!-- .chat-left-panel -->
                    <div class="chat-left-aside">
                        <div class="open-panel">
                            <i class="ti-angle-right"></i>
                        </div>
                        <div class="chat-left-inner form-horizontal">
                            <div class="form-material">
                                <div class="input-group searchbox">
                                    <input style="border:none;" type="text" class="form-control" id="uname"
                                        @keyup.enter="loadContact()" placeholder="Cari.." autocomplete="off"
                                        v-model="contactUiFlag.searchTerm">
                                    <!-- <input type="text" class="form-control" id="uname" placeholder="Pencarian..."
                                        v-model="contactUiFlag.searchTerm" @input="isTyping = true"> -->
                                    <div style="border:none;" class="input-group-addon">
                                        <a v-if="!showFilter" @click="showFilter = true">
                                            <v-icon>mdi-sort</v-icon>
                                        </a>
                                        <a v-if="showFilter" @click="showFilter = false">
                                            <v-icon>mdi-arrow-left</v-icon>
                                        </a>
                                    </div>
                                </div>
                            </div>
                            <div class="filter-sort-chat" v-if="showFilter">
                                <span class="filter">
                                    <div class="mb-4">
                                        <h6 class="mt-4 mb-4">Tipe Kontak</h6>
                                        <b-button pill size="sm" class="mr-2"
                                            :variant="filter.isGroup == '' ? 'primary' : 'secondary'"
                                            @click="filter.isGroup = ''"
                                        >
                                            Semua
                                        </b-button>
                                        <b-button pill size="sm" class="mr-2"
                                            :variant="filter.isGroup == 'personal' ? 'primary' : 'secondary'"
                                            @click="filter.isGroup = 'personal'"
                                        >
                                            Personal
                                        </b-button>
                                        <b-button pill size="sm" class="mr-2"
                                            :variant="filter.isGroup == 'group' ? 'primary' : 'secondary'"
                                            @click="filter.isGroup = 'group'"
                                        >
                                            Grup
                                        </b-button>
                                    </div>
                                    <div class="mb-4">
                                        <h6 class="mt-4 mb-4">Status</h6>
                                        <b-button pill size="sm" class="mr-2"
                                            :variant="filter.status == '' ? 'primary' : 'secondary'"
                                            @click="filter.status = ''"
                                        >
                                            Semua
                                        </b-button>
                                        <b-button pill size="sm" class="mr-2"
                                            :variant="filter.status == 'ACTIVE' ? 'primary' : 'secondary'"
                                            @click="filter.status = 'ACTIVE'"
                                        >
                                            Aktif
                                        </b-button>
                                        <b-button pill size="sm" class="mr-2"
                                            :variant="filter.status == 'VOID' ? 'primary' : 'secondary'"
                                            @click="filter.status = 'VOID'"
                                        >
                                            Non Aktif
                                        </b-button>
                                    </div>
                                    <div class="mb-4">
                                        <h6 class="mt-4 mb-4">Jenis</h6>
                                        <b-button pill size="sm" class="mr-2"
                                            :variant="filter.role == '' ? 'primary' : 'secondary'"
                                            @click="filter.role = ''"
                                        >
                                            Semua
                                        </b-button>
                                        <b-button pill size="sm" class="mr-2"
                                            :variant="filter.role == 'partner' ? 'primary' : 'secondary'"
                                            @click="filter.role = 'partner'"
                                        >
                                            Partner
                                        </b-button>
                                        <b-button pill size="sm" class="mr-2"
                                            :variant="filter.role == 'retail' ? 'primary' : 'secondary'"
                                            @click="filter.role = 'retail'"
                                        >
                                            Retail
                                        </b-button>
                                    </div>
                                </span>
                                <span class="pull-right">
                                    <b-button variant="secondary" class="mr-2" size="sm" style="border:1px solid rgb(237 237 237);"
                                        @click="cancelFilter()"
                                    >
                                        Batal
                                    </b-button>
                                    <b-button variant="primary" class="mr-2" size="sm" @click="customFilter()">
                                        Simpan
                                    </b-button>
                                </span>
                                <div class="clearfix"></div>
                            </div>
                            <div v-if="!showFilter" class="wrapp-filter-sort">
                                <b-badge pill class="sort-chat">
                                    <v-icon class="icon-sort-filter text-primary" left>mdi-account-box</v-icon> 
                                    {{ contactUiFlag.isGroup == 'personal' ? 'Personal' : contactUiFlag.isGroup == 'group' ? 'Grup' : 'Semua' }}
                                </b-badge>
                                <b-badge pill class="sort-chat">
                                    <v-icon class="icon-sort-filter text-primary" left>mdi-account-key</v-icon> 
                                    {{ contactUiFlag.status == 'ACTIVE' ? 'Aktif' : contactUiFlag.status == 'VOID' ? 'Non Aktif' : 'Semua' }}
                                </b-badge>
                                <b-badge pill class="sort-chat">
                                    <v-icon class="icon-sort-filter text-primary" left>mdi-account-star</v-icon> 
                                    {{ contactUiFlag.role == 'partner' ? 'Partner' : contactUiFlag.role == 'retail' ? 'Retail' : 'Semua' }}
                                </b-badge>
                            </div>
                            <div class="form-body" v-if="!$helpers.isBlank(contactUiFlag.error)">
                                <div class="form-material form-filter">
                                    <ErrorMessage :error="contactUiFlag.error" />
                                </div>
                            </div>
                            <div id="contact-loader-indicator" class="text-center mt-4 mb-4"
                                v-bind:class="{'d-none': !contactUiFlag.isLoading}">
                                <b-spinner small variant="secondary" label="Loading..."></b-spinner>
                            </div>
                            <div id="left-inner-scrollbar" data-scrollbar="true" tabindex="1"
                                style="overflow: hidden; outline: none;">
                                <div class="scroll-content">
                                    <ul class="chatonline style-none " v-if="!showFilter">
                                        <li v-for="item in contactList" :key="item.id">
                                            <router-link
                                                :to="{ name: 'contact' , params: {id: encodeURIComponent(item.id)}}"
                                                v-bind:class="{ active: item.selected }">
                                                <div class="wrapp-img">
                                                    <div class="initials">
                                                        <i class="mdi" :class="{ 
                                                            'mdi-account-multiple': item.isGroupContact == true || item.isGroupContact == 'true',
                                                            'mdi-account': item.isGroupContact == false || item.isGroupContact == 'false' || !item.isGroupContact,
                                                        }"></i>
                                                    </div>
                                                    <span class="is-affiliator" v-if="item.isAffiliator" title="Afiliator"> <i class="text-affiliator mdi mdi-link-variant "></i> </span>
                                                </div> <span class="chat-info"> <span
                                                        class="user-name">{{ item.name }}</span>
                                                    <small class="text-default">
                                                        <div class="chat-content-thumb">
                                                            {{ item.primaryPhoneNumber }}</div>
                                                    </small>
                                                </span>
                                                <span v-if="contact.isLoading && item.selected == true"><i
                                                        class="fa fa-circle-o-notch fa-spin"></i> </span>
                                                <span class="account-status"><i v-if="item.contactStatus == 'ACTIVE'"
                                                        title="Pengguna Aktif" class="mdi mdi-check-circle"
                                                        style="color:green;"></i> <i v-if="item.contactStatus == 'VOID'"
                                                        title="Pengguna Nonaktif" class="mdi mdi-minus-circle"
                                                        style="color:red;"></i></span>
                                            </router-link>
                                        </li>
                                        <!-- <li id="contact-loader-indicator" class="text-center"
                                            v-bind:class="{'d-none': !contactUiFlag.isLoading}">
                                            <b-spinner small variant="secondary" label="Loading..."></b-spinner>
                                        </li> -->
                                    </ul>
                                </div>
                                <div class="scrollbar-track scrollbar-track-x" style="display: none;">
                                    <div class="scrollbar-thumb scrollbar-thumb-x"
                                        style="width: 329px; transform: translate3d(0px, 0px, 0px);"></div>
                                </div>
                                <div class="scrollbar-track scrollbar-track-y" style="display: block;">
                                    <div class="scrollbar-thumb scrollbar-thumb-y"
                                        style="height: 20px; transform: translate3d(0px, 0px, 0px);"></div>
                                </div>
                            </div>
                        </div>
                        <div class="clearfix"></div>
                    </div>
                    <!-- .chat-left-panel -->
                    <!-- .chat-right-panel -->
                    <div class="chat-right-aside">
                        <div class="chat-main-header">
                            <div class="headchat">
                            </div>
                        </div>
                        <div class="chat-rbox">
                            <div class="wrapp-chat-content" data-scrollbar="true" tabindex="1"
                                style="overflow: hidden; outline: none;">
                                <div class="contact-add">
                                    <form class="form-horizontal form-material">
                                        <button id='btn-new' type="button" v-if="btnNewContact"
                                            class="pull-right btn btn-default waves-effect waves-light m-t-10 btn-new-contact"
                                            @click="$router.push({params: {id: ''}})">Buat
                                            Kontak Baru</button>
                                        <div class="clearfix"></div>
                                        <div class="wrapp-input-contact">
                                            <div class="row">
                                                <div class="col text-right text-white">
                                                    <span class="badge badge-pill badge-secondary">
                                                        <b>{{ contact.isGroupContact ? 'Grup' : 'Personal' }}</b>
                                                    </span>
                                                    <span class="badge badge-pill badge-secondary ml-2" v-if="contact.isAffiliator">
                                                        <b>Affiliator</b>
                                                    </span>
                                                </div>
                                            </div>
                                            <div class="row">
                                                <input v-model="contact.mode" type="hidden">
                                                <label for="f-contact-name"
                                                    class="col-sm-12 col-md-4 text-right control-label col-form-label">Nama
                                                    Lengkap</label>
                                                <div class="col-sm-12 col-md-6 pt-0">
                                                    <input v-model="contact.name" type="text" class="form-control"
                                                        id="f-contact-name" placeholder="">
                                                </div>
                                            </div>
                                            <div class="row">
                                                <label for="f-contact-id"
                                                    class="col-sm-12 col-md-4 text-right control-label col-form-label">ID
                                                    Kontak</label>
                                                <div class="col-sm-12 col-md-6 pt-0">
                                                    <input v-model="contact.generatedId" type="text"
                                                        v-if="contact.mode == 'new'" class="form-control"
                                                        id="f-contact-id" placeholder="">
                                                    <input v-model="contact.userId" type="text" disabled v-else
                                                        class="form-control" id="f-contact-id" placeholder="">
                                                </div>
                                            </div>
                                            <div class="row">
                                                <input v-model="contact.mode" type="hidden">
                                                <label for="f-contact-name"
                                                    class="col-sm-12 col-md-4 text-right control-label col-form-label">Status
                                                    kontak</label>
                                                <div class="col-sm-12 col-md-6">
                                                    <input type="checkbox" value="ACTIVE" id="accStatus"
                                                        class="filled-in chk-col-indigo" @click="check($event)">
                                                    <label class="ml-2"
                                                        for="accStatus">{{ contact.contactStatus == 'ACTIVE' ? 'Aktif' : ''  }}</label>
                                                </div>
                                            </div>
                                            <div class="row">
                                                <label for="telepohone-number"
                                                    class="col-sm-12 col-md-4  text-right control-label col-form-label">No
                                                    Telepon</label>
                                                <div class="col-sm-12 col-md-8 pt-0">
                                                    <div class="input-group">
                                                        <input type="text" id="telepohone-number" class="form-control"
                                                            v-on:keyup.enter="addPhoneToContact"
                                                            v-model="addedPhoneNumber">
                                                        <span class="input-group-btn">
                                                            <button class="btn btn-sm btn-primary" type="button"
                                                                title="Tambah" @click="addPhoneToContact"><i
                                                                    class="text-light fa fa-plus"
                                                                    aria-hidden="true"></i></button>
                                                        </span>
                                                    </div>
                                                    <ul class="list-unstyled list-phone">
                                                        <template v-for="(item, index) in contact.communicatorChannels">
                                                            <li :key="index">{{ item.number }}
                                                                <input type="checkbox" :id="'lbl-sms-' + index"
                                                                    class="ml-4 filled-in chk-col-indigo"
                                                                    v-model="item.sms"><label class="ml-1"
                                                                    :for="'lbl-sms-' + index">SMS</label>&nbsp;
                                                                <input type="checkbox" :id="'lbl-wa-' + index"
                                                                    class="filled-in chk-col-indigo ml-2"
                                                                    v-model="item.wa"><label class="ml-1"
                                                                    :for="'lbl-wa-' + index">WA</label>&nbsp;
                                                                <input type="radio" value="One">
                                                                <div class="action-phone pull-right"
                                                                    v-if="item.number != contact.primaryPhoneNumber">
                                                                    <a href="#" title="Hapus Nomor"
                                                                        v-on:click="deletePhoneFromContact($event, item.number)"
                                                                        class="btn btn-xs btn-danger mr-2 text-light">Hapus</a>
                                                                    <a href="#" title="Set Utama"
                                                                        v-on:click="setPrimaryPhone($event, item.number)"
                                                                        class="btn btn-xs btn-primary">Set Utama</a>
                                                                </div>
                                                                <div class="action-phone pull-right"
                                                                    v-if="item.number == contact.primaryPhoneNumber">
                                                                    <div class="action-phone pull-right">
                                                                        <span
                                                                            class="btn btn-xs btn-success text-light">Utama</span>
                                                                    </div>
                                                                </div>
                                                            </li>
                                                            <div class="clearfix" :key="index + ':'"></div>
                                                        </template>
                                                    </ul>
                                                </div>
                                            </div>
                                            <div class="row">
                                                <label for="f-email"
                                                    class="col-sm-12 col-md-4 text-right control-label col-form-label">Email</label>
                                                <div class="col-sm-12 col-md-8 pt-0">
                                                    <div class="input-group">
                                                        <input type="text" id="f-email" v-model="addedEmail"
                                                            v-on:keyup.enter="addEmailToContact" class="form-control">
                                                        <span class="input-group-btn">
                                                            <button id="add-email-button" type="button"
                                                                class="btn btn-primary btn-sm" title="Tambah"
                                                                @click="addEmailToContact"><i
                                                                    class="text-light fa fa-plus"
                                                                    aria-hidden="true"></i></button>
                                                        </span>
                                                    </div>
                                                    <ul class="list-unstyled list-phone">
                                                        <template v-for="(item, index) in contact.emails">
                                                            <li :key="index">{{ item.email }}
                                                                <span v-if="item.primary">*</span>
                                                                <div class="action-phone pull-right"
                                                                    v-if="!item.primary">
                                                                    <a href="#" title="Hapus Email"
                                                                        v-on:click="deleteEmailFromContact($event, item.email)"
                                                                        class="btn btn-xs btn-danger mr-2 text-light">Hapus</a>
                                                                    <a href="#" title="Set Utama"
                                                                        v-on:click="setPrimaryEmail($event, item.email)"
                                                                        class="btn btn-xs btn-primary">Set Utama</a>
                                                                </div>
                                                                <div class="action-phone pull-right"
                                                                    v-if="item.primary">
                                                                    <span
                                                                        class="btn btn-xs btn-success text-light">Utama</span>
                                                                </div>
                                                            </li>
                                                            <div class="clearfix" :key="index + ':'"></div>
                                                        </template>
                                                    </ul>
                                                </div>
                                            </div>

                                            <div class="row"
                                                v-if="contact.groups.length > 0 && contact.mode == 'edit'">
                                                <label for="groups"
                                                    class="col-sm-12 col-md-4 text-right control-label col-form-label">Grup</label>
                                                <div class="col-sm-12 col-md-8">
                                                    <ul v-for="(group, index) in contact.groups" :key="group._id + index">
                                                        <li>{{ group.name }}</li>
                                                    </ul>
                                                </div>
                                            </div>

                                            <div class="row"
                                                v-if="contact.subscriptions.length > 0 && contact.mode == 'edit'">
                                                <label for="subscriptions"
                                                    class="col-sm-12 col-md-4 text-right control-label col-form-label">Langganan</label>
                                                <div class="col-sm-12 col-md-8">
                                                    <div v-for="(subs, index) in contact.subscriptions"
                                                        :key="subs._id + index">
                                                        <v-checkbox class="pt-0 mt-0" style="margin-bottom:-20px;"
                                                            true-value="SUBSCRIBED" false-value="UNSUBSCRIBED"
                                                            id="subscriptions" v-model="subs.status"
                                                            :label="`${subs._id}`"></v-checkbox>
                                                    </div>
                                                </div>
                                            </div>

                                            <div class="row">
                                                <label for="telepohone-number"
                                                    class="col-sm-12 col-md-4  text-right control-label col-form-label">Akun
                                                    Utama</label>
                                                <div class="col-sm-12 col-md-5 pt-0 mt-0">
                                                    <v-autocomplete v-model="selectedInputContact" class="mt-0 pt-0"
                                                        :loading="isLoadingContact" :items="inputContactList"
                                                        :search-input.sync="searchInputContact" :no-filter="true"
                                                        item-value="id" hide-no-data hide-selected>
                                                        <template v-slot:selection="data">
                                                            <span class="ml-2">
                                                                {{ data.item.name }}
                                                            </span>
                                                        </template>
                                                        <template v-slot:item="data">
                                                            {{ data.item.id }} - ({{ data.item.name }})
                                                        </template>
                                                    </v-autocomplete>
                                                </div>
                                            </div>
                                            <div class="row" v-if="contact.mode == 'edit'">
                                                <label
                                                    class="col-sm-12 col-md-4  text-right control-label col-form-label">Gabungkan
                                                    Akun</label>
                                                <div class="col-sm-12 col-md-5 pt-0 mt-0">
                                                    <v-autocomplete v-model="selectedCmrcContact" class="mt-0 pt-0"
                                                        :loading="isLoadingCmrcContact" :items="inputCmrcContactList"
                                                        :search-input.sync="searchInputCmrcContact" 
                                                        :no-filter="true" hide-no-data hide-selected>
                                                        <template v-slot:selection="data">
                                                            <span class="ml-2">
                                                                {{ data.item.name }}
                                                            </span>
                                                        </template>
                                                        <template v-slot:item="data">
                                                            {{ data.item.id }} - ({{ data.item.name }})
                                                        </template>
                                                    </v-autocomplete>
                                                </div>
                                                <div class="col-sm-12 col-md-3 pt-0 mt-0">
                                                    <button class="btn btn-default waves-effect waves-light" type="button" @click="mergedAccount">
                                                        &nbsp;Gabungkan</button>
                                                </div>

                                            </div>
                                            <!-- <div class="row">
                                                <label
                                                    class="col-sm-12 col-md-4  text-right control-label col-form-label"></label>
                                                <div class="col-sm-12 col-md-5 pt-0 mt-0">
                                                    <div>
                                                        <b-modal id="modal-1" title="Riwayat penggabungan kontak">
                                                            <table class="table table-bordered">
                                                                <thead>
                                                                    <tr>
                                                                        <th scope="col">Nama Akun<br>No.HP</th>
                                                                        <th scope="col">Tanggal<br>status</th>
                                                                        <th scope="col">Operator</th>
                                                                    </tr>
                                                                </thead>
                                                                <tbody>
                                                                    <tr>
                                                                        <td>Nenden<br>0825645121</td>
                                                                        <td>16/02/2023<br>berhasil</td>
                                                                        <td>Rama</td>
                                                                    </tr>
                                                                </tbody>
                                                            </table>
                                                        </b-modal>
                                                    </div>
                                                </div>

                                            </div> -->
                                            <div class="text-danger text-center">
                                                <ErrorMessage :error="contact.error" />
                                            </div>
                                        </div>
                                        <div>
                                        </div>
                                        <div class="row button-wrapp">
                                            <div class="row">
                                                <div class="col-sm-12">
                                                    <button :disabled="contact.isSaving" id='btn-save' type="button"
                                                        style="margin-right: 10px;"
                                                        class="btn btn-primary waves-effect waves-light m-t-10"
                                                        @click="saveContact">
                                                        <i v-if="contact.isSaving"
                                                            class="fa fa-circle-o-notch fa-spin"></i>&nbsp;Simpan</button>
                                                    <!-- <b-button class="m-t-10" v-b-modal.modal-1 v-if="contact.mode == 'edit'"><i
                                                            class="mdi mdi-history"></i> Lihat
                                                        riwayat penggabungan</b-button> -->
                                                    <!-- <button type="button" class="btn btn-default waves-effect waves-light m-t-10">Hapus</button> -->
                                                    <button id='btn-send' type="button" v-if="btnSendMessage"
                                                        class="btn btn-default waves-effect waves-light m-t-10"
                                                        @click="sendMessage">&nbsp;Kirim
                                                        Pesan</button>
                                                </div>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                    <!-- .chat-right-panel -->
                </div>
                <!-- /.chat-row -->
            </div>
        </div>
        <Alert :message="alertMessage" />
        <SuccessNotif />
    </div>
</template>

<script>
    import Scrollbar from 'smooth-scrollbar';
    import axios from "axios";
    import MainLayout from "../layouts/MainLayout"
    import ErrorMessage from '../components/ErrorMessage'
    import Alert from '../components/Alert'
    import SuccessNotif from "../components/SuccessNotif"

    const phoneUtil = require('google-libphonenumber').PhoneNumberUtil.getInstance();

    import {
        mapState
    } from 'vuex'

    const $ = require("jquery")
    const {
        toFriendlyDateTime
    } = require('../utils/date-utils');
    const {
        commonpagesetup,
        mainuisetup
    } = require('../utils/ui-setup');
    var debounce = require('lodash.debounce');
    const {
        phoneNumberEqual
    } = require('../utils/phonenumber-utils')

    var contactScrollBar = null;

    const CONTACT_ROW_PER_PAGE = 25;

    export default {
        computed: {
            ...mapState({
                sessionInfo: state => state.session.sessionInfo
            })
        },
        data: function () {
            return {
                showFilter: false,

                selectedCmrcContact: Object(),
                searchInputCmrcContact: String(),
                isLoadingCmrcContact: false,
                inputCmrcContactList: [],

                isLoadingContact: false,
                searchInputContact: String(),
                selectedInputContact: String(),
                inputContactList: [],

                operatorList: [],
                contactList: [],

                filter: {
                    isGroup: String(),
                    status: String(),
                    role: String(),
                },

                contactUiFlag: {
                    isLoading: false,
                    currentPage: 1,
                    isGroup: String(),
                    status: String(),
                    role: String(),
                    searchTerm: '',
                    selectedContact: '',
                    selectedContactName: '',
                    selectedContactPrimaryNumber: '',
                    selectedContactDefaultChannel: '',
                    hasMoreData: true,
                    error: {}
                },
                msgUiFlag: {
                    currentPage: 1,
                    hasMoreData: true
                },
                contact: {
                    isSaving: false,
                    isLoading: false,
                    mode: 'new',
                    generatedId: '',
                    userId: '',
                    name: '',
                    communicatorChannels: [],
                    emails: [],
                    isGroupContact: '',
                    isAffiliator: '',
                    contactStatus: 'VOID',
                    subscriptions: [],
                    primaryPhoneNumber: '',
                    groups: [],
                    error: {}
                },
                addedPhoneNumber: '',
                addedEmail: '',
                saveErrorMessage: '',
                isTyping: false,
                btnSendMessage: false,
                btnNewContact: true,
                listNoPhone: false,
                alertMessage: {}
            }
        },
        watch: {
            'contact.name': function (val, old) {
                if (old == '' || this.contact.generatedId == old.toLowerCase().replace(/[\-\s]/g, "_").replace(
                        /[^a-zA-Z0-9_]/g,
                        "")) {
                    this.contact.generatedId = val.replace(/[\-\s]/g, "_").replace(/[^a-zA-Z0-9_]/g, "")
                        .toLowerCase();
                }
            },
            'contactUiFlag.searchTerm': debounce(async function () {
                if (this.contactUiFlag.searchTerm == "") {
                    await this.loadContact() 
                }
            }, 1000),
            // isTyping: function (value) {
            //     if (!value) {
            //         this.loadContact();
            //     }
            // },
            sessionInfo: function (val) {
                if (!this.$helpers.isBlank(val) && val.isLoggedIn) {
                    this.loadInitialData();
                }
            },
            $route: async function (to, from) {
                if (this.$helpers.isBlank(to.params.id)) {
                    this.resetToEmptyForm();
                } else if (to.params.id != from.params.id) {
                    this.selectContact(decodeURIComponent(to.params.id));
                }
            },
            searchInputContact(val) {
                if (val && (val.length === 0 || val.length > 3)) this.searchInputContactWithDelay(val);
            },
            searchInputCmrcContact(val) {
                if (val && (val.length === 0 || val.length > 3)) this.searchInputCmrcContactWithDelay(val);
            }
        },
        methods: {
            customFilter: async function() {
                this.showFilter = false
                this.contactUiFlag.isGroup = this.filter.isGroup
                this.contactUiFlag.status = this.filter.status
                this.contactUiFlag.role = this.filter.role

                await this.loadContact()
            },
            cancelFilter: async function() {
                this.showFilter = false
                this.filter.isGroup = this.contactUiFlag.isGroup
                this.filter.status = this.contactUiFlag.status
                this.filter.role = this.contactUiFlag.role
            },
            mergedAccount: async function() {
                const id = decodeURIComponent(this.$route.params.id)
                const refId = this.selectedCmrcContact.id

                try {
                    await axios.put(process.env.VUE_APP_API_URL + '/contact/merge/' + id, {
                        "refId": refId
                    })

                    const index = this.contactList.findIndex(e => e.id == refId)
                    if(index >= 0) this.contactList[index].contactStatus = 'VOID'
                    
                    this.selectContact(id)
                } catch (error) {
                    console.log("merge account", error);
                }
            },
            check: function (e) {
                if (e.target.checked) {
                    this.contact.contactStatus = "ACTIVE";
                } else {
                    this.contact.contactStatus = 'VOID';
                }
            },
            resetToEmptyForm: function () {
                this.btnSendMessage = false;
                this.contact.mode = 'new'
                this.contact.userId = ''
                this.contact.name = ''
                this.contact.communicatorChannels = []
                this.contact.emails = []
                this.contact.primaryPhoneNumber = ''
                this.contact.contactStatus = 'VOID'
                this.contact.isGroupContact = ''
                this.contact.isAffiliator = ''
                this.error = {}
                $("#accStatus").prop("checked", false);
                this.contactList.forEach(element => {
                    if (element.selected) element.selected = false;
                });
                this.selectedCmrcContact = Object()
                this.searchInputCmrcContact = String()
                this.inputCmrcContactList = []

            },
            deletePhoneFromContact: function (event, number) {
                this.contact.communicatorChannels.splice(this.contact.communicatorChannels.findIndex(item =>
                    phoneNumberEqual(number, item.number, this.sessionInfo.defaultCountryCode)), 1);
                event.preventDefault();
            },
            deleteEmailFromContact: function (event, email) {
                this.contact.emails.splice(this.contact.emails.findIndex(item =>
                    item.email == email), 1);
                event.preventDefault();
            },
            addPhoneToContact: function (event) {
                event.preventDefault();
                this.saveErrorMessage = '';
                if (!this.$helpers.isBlank(this.addedPhoneNumber)) {
                    var isPrimary = this.contact.communicatorChannels.length == 0;
                    const number = phoneUtil.parseAndKeepRawInput(this.addedPhoneNumber, this.sessionInfo
                        .defaultCountryCode);
                    if (isPrimary) this.contact.primaryPhoneNumber = this.addedPhoneNumber;
                    this.contact.communicatorChannels.push({
                        wa: true,
                        sms: true,
                        number: this.addedPhoneNumber,
                        primary: isPrimary,
                        intnumber: '+' + number.getCountryCode() + number.getNationalNumber()
                    });

                    this.addedPhoneNumber = '';
                }
            },
            addEmailToContact: function (event) {
                event.preventDefault();
                if (!this.$helpers.isBlank(this.addedEmail)) {
                    var isPrimary = this.contact.emails.length == 0;
                    this.contact.emails.push({
                        primary: isPrimary,
                        email: this.addedEmail
                    });
                    this.addedEmail = '';
                }
            },
            selectContact: async function (contactId) {
                this.resetToEmptyForm()
                for (var i = 0; i < this.contactList.length; i++) {
                    if (this.contactList[i].id == contactId) {
                        this.contactList[i].selected = true;
                    } else {
                        this.contactList[i].selected = false;
                    }
                }

                this.contact.error = {}
                if (!this.$helpers.isBlank(contactId)) {
                    this.contact.isLoading = true;
                    try {
                        var resp = await axios.get(process.env.VUE_APP_API_URL + '/contact/id/' +
                            encodeURIComponent(
                                contactId));
                        var jsonData = resp.data;

                        if (jsonData.parentAccountId) {
                            const check = this.inputContactList.find(e => e.id == encodeURIComponent(contactId))
                            if (!check) await this.searchContact(jsonData.parentAccountId, true)
                        } else {
                            this.inputContactList = [...this.contactList]
                        }

                        this.contact.mode = 'edit'
                        this.contact.primaryPhoneNumber = jsonData.primaryPhoneNumber
                        this.contact.userId = contactId
                        this.contact.name = jsonData.name
                        this.contact.contactStatus = jsonData.contactStatus
                        this.contact.isGroupContact = jsonData.isGroupContact
                        this.contact.isAffiliator = jsonData.isAffiliator

                        const tempGroup = Array()
                        if(jsonData.groups) {
                            jsonData.groups.forEach(e => {
                                const group = tempGroup.find(group => group._id == e._id);

                                if(tempGroup.length < 1) {
                                    tempGroup.push(e)
                                } else {
                                    if(!group) tempGroup.push(e)
                                }
                            })
                        }
                        this.contact.groups = tempGroup

                        this.selectedInputContact = jsonData.parentAccountId
                        if (jsonData.subscriptions) this.contact.subscriptions = jsonData.subscriptions
                        this.contact.communicatorChannels = jsonData.communicatorChannels.map((item) => {
                            const number = phoneUtil.parseAndKeepRawInput(item.number,
                                this.sessionInfo.defaultCountryCode);
                            return Object.assign({
                                intnumber: '+' + number.getCountryCode() + number
                                    .getNationalNumber(),
                                primary: phoneNumberEqual(item.number, jsonData
                                    .primaryPhoneNumber, this.sessionInfo.defaultCountryCode
                                ),
                            }, item)
                        })
                        this.contact.emails = jsonData.emails || []

                        if (jsonData.contactStatus == 'ACTIVE') {
                            $("#accStatus").prop("checked", true);
                            this.btnSendMessage = true;
                        } else {
                            $("#accStatus").prop("checked", false);
                            this.btnSendMessage = false;
                        }

                    } catch (error) {
                        this.contact.error = this.$helpers.createError(error);
                    }
                    this.contact.isLoading = false;
                }
            },
            refreshContact: function () {
                this.msgUiFlag.currentPage = 1
                this.loadContact()
            },
            loadContact: async function () {
                this.contactList = [];
                this.contactUiFlag.error = {};

                this.contactUiFlag.currentPage = 1;
                this.contactUiFlag.hasMoreData = true;

                this.contactUiFlag.isLoading = true;
                try {
                    var resp = await axios.get(process.env.VUE_APP_API_URL + '/contact/', {
                        params: {
                            page: this.contactUiFlag.currentPage,
                            term: this.contactUiFlag.searchTerm,
                            size: CONTACT_ROW_PER_PAGE,
                            isGroup: this.contactUiFlag.isGroup,
                            status: this.contactUiFlag.status,
                            role: this.contactUiFlag.role
                        }
                    });
                    this.contactUiFlag.isLoading = false;
                    var jsonData = resp.data;
                    this.contactUiFlag.hasMoreData = jsonData.page.number < jsonData.page.totalPages;
                    this.contactList = jsonData.content.map((val) => {
                        val.time = toFriendlyDateTime(val.lastActive);
                        return val;
                    });
                } catch (error) {
                    this.contactUiFlag.isLoading = false;
                    this.contactUiFlag.error = this.$helpers.createError(error)
                }
            },
            loadMoreContacts: async function () {
                this.contactUiFlag.currentPage++;
                this.contactUiFlag.error = {};
                this.contactUiFlag.isLoading = true;
                try {
                    var resp = await axios.get(process.env.VUE_APP_API_URL + '/contact/', {
                        params: {
                            page: this.contactUiFlag.currentPage,
                            term: this.contactUiFlag.searchTerm,
                            size: CONTACT_ROW_PER_PAGE,
                            isGroup: this.contactUiFlag.isGroup,
                            status: this.contactUiFlag.status,
                            role: this.contactUiFlag.role,
                        }
                    });
                    this.contactUiFlag.isLoading = false;
                    var jsonData = resp.data;
                    this.contactUiFlag.hasMoreData = jsonData.page.number < jsonData.page.totalPages;
                    var tmp = jsonData.content.map((val) => {
                        val.time = toFriendlyDateTime(val.lastActive);
                        return val;
                    });

                    this.contactList.splice(this.contactList.length, 0, ...tmp);
                } catch (error) {
                    this.contactUiFlag.isLoading = false;
                    this.contactUiFlag.error = this.$helpers.createError(error)
                }
            },
            saveContact: async function () {
                this.contact.error = {}
                var messages = [];
                if (this.contact.mode == "new" && this.$helpers.isBlank(this.contact.generatedId)) {
                    messages.push("ID kontak tidak boleh kosong")
                }
                if (this.$helpers.isBlank(this.contact.name)) {
                    messages.push("Nama tidak boleh kosong")
                }
                if (this.$helpers.isBlank(this.contact.primaryPhoneNumber)) {
                    messages.push("No telepon")
                }
                //if (this.contact.emails.length == 0) {
                //messages.push("Email tidak boleh kosong")
                //}

                if (!this.$helpers.isBlank(messages)) {
                    this.contact.isSaving = false;
                    this.contact.error = this.$helpers.createErrorFromList(messages);
                } else {

                    const parent = this.inputContactList.find(e => e.id == this.selectedInputContact)

                    var postData = {
                        id: this.contact.mode == 'edit' ? this.contact.userId : this
                            .contact.generatedId.toLowerCase(),
                        primaryPhoneNumber: this.contact.primaryPhoneNumber,
                        name: this.contact.name,
                        communicatorChannels: this.contact.communicatorChannels,
                        emails: this.contact.emails,
                        contactStatus: this.contact.contactStatus,
                        parentAccountId: parent ? parent.id : null,
                        parentAccountName: parent ? parent.name : null,
                    };

                    if (this.contact.subscriptions.length > 0 && this.contact.mode == 'edit') {
                        postData.subscriptions = this.contact.subscriptions
                    }

                    this.contact.isSaving = true;
                    try {
                        if (this.contact.mode == 'edit') {

                            await axios.put(process.env.VUE_APP_API_URL + '/contact/', postData);

                            const index = this.contactList.findIndex(e => e.id == postData.id)

                            if (index != -1) {
                                // this.contactList[index].contactStatus = postData.contactStatus
                                this.contactList[index] = {
                                    ...this.contactList[index],
                                    ...postData
                                }
                            }

                        } else {
                            console.log(postData, "add");
                            await axios.post(process.env.VUE_APP_API_URL + '/contact/', postData);
                        }
                        this.$bvToast.show('success-notif')
                        this.contact.userId = postData.id;
                        this.contact.mode = 'edit';
                        if (this.contact.contactStatus == 'ACTIVE') {
                            this.btnSendMessage = true;
                        } else {
                            this.btnSendMessage = false;
                        }
                    } catch (error) {
                        this.contact.error = this.$helpers.createError(error);
                    }
                    this.contact.isSaving = false;
                }
            },
            setPrimaryPhone: function (event, phoneNo) {
                event.preventDefault();
                this.contact.primaryPhoneNumber = phoneNo;
            },
            setPrimaryEmail: function (event, email) {
                event.preventDefault();
                var newList = [];
                for (var i = 0; i < this.contact.emails.length; i++) {
                    var tmp = this.contact.emails[i];
                    if (tmp.email == email) {
                        tmp.primary = true;
                    } else {
                        delete tmp.primary;
                    }
                    newList.push(tmp);
                }
                this.contact.emails = newList;
            },
            sendMessage: function (event) {
                event.preventDefault();
                this.error = {}
                console.log('userid: ' + this.contact.userId)
                if (this.contact.contactStatus == 'ACTIVE') {
                    this.$router.push({
                        name: 'myconversation',
                        params: {
                            id: encodeURIComponent(this.contact.userId)
                        },
                        query: {
                            status: 'open',
                            sortOrder: 'lastActive'
                        }
                    });
                } else {
                    this.error.message = 'Status kontak tidak aktif';
                }
            },
            loadInitialData: async function () {
                await this.loadContact();
                if (this.$route.params.id != null) {
                    this.selectContact(decodeURIComponent(this.$route.params.id));
                }
            },
            searchContact: async function (val, isDetail) {
                this.isLoadingContact = true
                try {
                    if (!isDetail) {
                        var {
                            data
                        } = await axios.get(process.env.VUE_APP_API_URL + '/contact/', {
                            params: {
                                page: 0,
                                term: val,
                                size: CONTACT_ROW_PER_PAGE
                            }
                        });

                        this.inputContactList = [...this.inputContactList, ...data.content]
                    } else {
                        var res = await axios.get(process.env.VUE_APP_API_URL + '/contact/id/' + val);
                        this.inputContactList = [res.data]
                    }

                } catch (error) {
                    console.log("search-input-contact", error);
                }
                this.isLoadingContact = false
            },
            searchCmrcContact: async function (val) {
                this.isLoadingCmrcContact = true
                try {
                    var {
                        data
                    } = await axios.get(process.env.VUE_APP_API_URL + '/contact', {
                        params: {
                            page: 0,
                            term: val,
                            size: CONTACT_ROW_PER_PAGE,
                            status: 'ACTIVE'
                        }
                    });



                    this.inputCmrcContactList = data.content.filter(e => e.id != this.$route.params.id)
                } catch (error) {
                    console.log("search-input-contact-cmrc", error);
                }
                this.isLoadingCmrcContact = false
            },
            searchInputContactWithDelay(val) {
                clearTimeout(this._timerId);
                this._timerId = setTimeout(() => this.searchContact(val, false), 500);
            },
            searchInputCmrcContactWithDelay(val) {
                clearTimeout(this._timerId);
                this._timerId = setTimeout(() => this.searchCmrcContact(val), 500);
            }, 
        },
        created: function () {
            this.$emit('update:layout', MainLayout);
        },
        mounted: function () {
            var self = this
            Scrollbar.initAll();
            contactScrollBar = Scrollbar.get($('#left-inner-scrollbar').get()[0]);
            contactScrollBar.addListener((status) => {
                if (this.contactUiFlag.hasMoreData && status.offset.y == contactScrollBar.limit.y) {
                    setTimeout(() => self.loadMoreContacts(), 500);
                }
            });
            if (!this.$helpers.isBlank(this.sessionInfo) && this.sessionInfo.isLoggedIn) {
                this.loadInitialData();
            }
            try {
                mainuisetup();
                commonpagesetup();
            } catch (err) {
                console.log(err);
            }
        },
        components: {
            ErrorMessage,
            Alert,
            SuccessNotif
        }
    }
</script>